import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

Vue.config.productionTip = false;

Vue.use(VueRouter)

let routes = [
  { path: '/', component: () => import('@/Main.vue') },
  { path: '/qa', component: () => import('@/components/Helper.vue') },
  { path: '/d/*', component: () => import('@/components/DirectDownload.vue') },
  { path: '*', redirect: '/' }
]

let router = new VueRouter({
  mode: 'history',
  routes // (缩写) 相当于 routes: routes
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
