<template>
  <div :class="`foot ${this.$route.path=='/'?'white':''}`">
    <p>广州百田信息科技有限公司</p>
    <p>Copyright © 2009-{{ thisyear }} BaitianInfo Ltd.All Rights Reserved</p>
  </div>
</template>

<script>
export default {
  data() {
    const thisyear = new Date().getFullYear();
    return {
      thisyear,
    };
  },
};
</script>

<style scoped>
.foot {
  padding: 40px;
  color: #bdbdbd;
  font-family: "微软雅黑","黑体";
}
.foot.white {
  background-color: #FFF;
  color: #2c3e50;
}
.foot p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}
</style>